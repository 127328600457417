var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"absent"},[_c('h2',[_vm._v("Absent students report")]),_c('v-row',{class:'absent-header'},[_c('v-col',{attrs:{"cols":""}},[_c('div',{class:' d-flex justify-start'},[_c('v-select',{class:'select-input',attrs:{"item-text":"location","append-icon":"keyboard_arrow_down","item-value":"locationID","items":_vm.listLocation,"label":"Center","outlined":"","dense":""},model:{value:(_vm.locationID),callback:function ($$v) {_vm.locationID=$$v},expression:"locationID"}}),_c('v-text-field',{class:'select-input',staticStyle:{"margin-left":"-20px"},attrs:{"type":"date","label":"Select date","outlined":"","dense":"","hide-details":""},on:{"change":_vm.changeFromDate},model:{value:(_vm.fromDate),callback:function ($$v) {_vm.fromDate=$$v},expression:"fromDate"}}),_c('v-text-field',{class:'select-input',staticStyle:{"margin-left":"-20px"},attrs:{"type":"date","label":"Select date","outlined":"","dense":"","hide-details":""},on:{"change":_vm.changeToDate},model:{value:(_vm.toDate),callback:function ($$v) {_vm.toDate=$$v},expression:"toDate"}})],1)]),_c('v-col',[_c('div',{staticClass:"btn-download"},[_c('v-btn',{attrs:{"elevation":"0","height":"36","color":"primary"},on:{"click":function($event){return _vm.download()}}},[_vm._v(" Download file excel ")])],1)])],1),_c('h4',[_vm._v("Total : "+_vm._s(_vm.listStudent.length)+" students")]),[_c('v-data-table',{staticClass:"elevation-0 absent-table",attrs:{"headers":_vm.headers,"items":_vm.filtered,"page":_vm.page,"items-per-page":_vm.itemsPerPage,"hide-default-footer":"","id":'absent-table',"fixed-header":"","height":"60vh"},on:{"update:page":function($event){_vm.page=$event},"page-count":function($event){_vm.pageCount = $event}},scopedSlots:_vm._u([{key:"header",fn:function(ref){
var props = ref.props;
return [_c('tr',{staticClass:"fixed-row-filter"},_vm._l((props.headers),function(header){return _c('th',{key:header.text},[(_vm.filters.hasOwnProperty(header.value))?_c('div',[(_vm.filters.hasOwnProperty(header.value))?_c('Autocomplete',{attrs:{"styleBorder":'border-bottom',"keyFilter":header.value,"selectedInit":_vm.filters[header.value],"listItem":_vm.groupColumnValueList(header.value)},on:{"setFilter":_vm.setFilter}}):_vm._e()],1):_vm._e()])}),0)]}},{key:"item.classcode",fn:function(ref){
var item = ref.item;
return [_c('p',{staticClass:"link",staticStyle:{"width":"150px"},on:{"click":function($event){return _vm.viewClassDetail(item.classID)}}},[_vm._v(" "+_vm._s(item.classcode)+" ")])]}},{key:"item.makeUpClassCode",fn:function(ref){
var item = ref.item;
return [_c('p',{staticClass:"link",staticStyle:{"width":"150px"},on:{"click":function($event){return _vm.viewClassDetail(item.makeUpClassID)}}},[_vm._v(" "+_vm._s(item.makeUpClassCode)+" ")])]}},{key:"item.no",fn:function(ref){
var index = ref.index;
return [_c('p',[_vm._v(_vm._s(index + (_vm.page - 1) * 20 + 1))])]}},{key:"item.classGroup",fn:function(ref){
var item = ref.item;
return [(item.classGroup === 'Make-up')?_c('p',{class:'type-makup lesson-type'},[_vm._v(" "+_vm._s(item.classGroup)+" ")]):_vm._e(),(item.classGroup === 'Official')?_c('p',{class:'type-public lesson-type'},[_vm._v("Official")]):_vm._e()]}},{key:"item.fullname",fn:function(ref){
var item = ref.item;
return [_c('p',{staticClass:"link",staticStyle:{"width":"200px"},on:{"click":function($event){return _vm.viewStudent(item.student_userID)}}},[_vm._v(" "+_vm._s(item.fullname)+" ")])]}},{key:"item.qc",fn:function(ref){
var item = ref.item;
return [_c('p',{staticClass:"link",staticStyle:{"width":"200px"},on:{"click":function($event){return _vm.viewStaff(item.qC_userID)}}},[_vm._v(_vm._s(item.qc))])]}},{key:"item.ec",fn:function(ref){
var item = ref.item;
return [_c('p',{staticClass:"link",staticStyle:{"width":"200px"},on:{"click":function($event){return _vm.viewStudent(item.eC_userID)}}},[_vm._v(" "+_vm._s(item.ec)+" ")])]}},{key:"item.remarkAttendance",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex",staticStyle:{"width":"150px"},on:{"click":function($event){return _vm.openEdit(item)}}},[_c('img',{staticClass:"mr-1",attrs:{"src":require("../../assets/images/absent_report_edit_note.svg"),"alt":""}}),_c('p',[_vm._v(_vm._s(item.remarkAttendance))])])]}},{key:"item.mkStatus",fn:function(ref){
var item = ref.item;
return [_c('div',{style:({ width: 'fit-content', margin: '0 auto' })},[_c('img',{style:({
              width: item.mkStatus === 'Absent' ? '34px' : '27px',
            }),attrs:{"src":item.mkStatus === 'Absent'
                ? require('../../assets/images/icon-close-red.svg')
                : item.mkStatus === 'Attended'
                ? require('../../assets/images/icon-done.svg')
                : item.mkStatus === 'Pending'
                ? require('../../assets/images/icon-pendding.svg')
                : '',"alt":"","srcset":""}})])]}},{key:"item.action",fn:function(ref){
                var item = ref.item;
return [_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                var on = ref.on;
                var attrs = ref.attrs;
return [_c('img',_vm._g(_vm._b({style:({
                opacity:
                  !(
                    item.classGroup === 'Make-up' ||
                    _vm.dayjs().isBefore(_vm.dayjs(item.makeUpDateWithShift))
                  ) && item.makeUpID
                    ? 0.4
                    : 1,
              }),attrs:{"src":require("../../assets/images/icon-edit-pen.svg")},on:{"click":function($event){item.classGroup === 'Make-up' ||
                  (item.makeUpDate && _vm.dayjs().isAfter(_vm.dayjs(item.makeUpDateWithShift))) ||
                  _vm.openForm(item)}}},'img',attrs,false),on))]}}],null,true)},[_c('span',[_vm._v(" "+_vm._s(item.classGroup === 'Make-up' || (item.makeUpDate && _vm.dayjs().isAfter(_vm.dayjs(item.makeUpDateWithShift))) ? "Can't Add/Edit" : 'Add/Edit'))])])]}}],null,true)}),_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.listStudent,"items-per-page":10000000000,"hide-default-footer":"","id":"stu-absent-table","fixed-header":"","height":"0vh"},scopedSlots:_vm._u([{key:"body",fn:function(ref){
                  var items = ref.items;
return _vm._l((items),function(item,index){return _c('tbody',{key:index},[_c('tr',[_c('td',[_c('p',[_vm._v(_vm._s(index + 1))])]),_c('td',[_c('p',[_vm._v(" "+_vm._s(item.studentCode)+" ")])]),_c('td',[_c('p',[_vm._v(" "+_vm._s(item.fullname)+" ")])]),_c('td',[_c('p',[_vm._v(" "+_vm._s(item.mobilePhone)+" ")])]),_c('td',[_c('p',[_vm._v(" "+_vm._s(item.classcode)+" ")])]),_c('td',[_c('p',[_vm._v(" "+_vm._s(item.classDate)+" ")])]),_c('td',[_c('p',[_vm._v(" "+_vm._s(item.classTime)+" ")])]),_c('td',[_c('p',[_vm._v(" "+_vm._s(item.absentDate)+" ")])]),_c('td',[_c('p',[_vm._v(" "+_vm._s(item.classGroup)+" ")])]),_c('td',[_c('p',[_vm._v(" "+_vm._s(item.qc)+" ")])]),_c('td',[_c('p',[_vm._v(" "+_vm._s(item.ec)+" ")])]),_c('td',[_c('p',[_vm._v(" "+_vm._s(item.mkStatus)+" ")])]),_c('td',[_c('p',[_vm._v(" "+_vm._s(item.makeUpClassCode)+" ")])]),_c('td',[_c('p',[_vm._v(" "+_vm._s(item.makeUpDate)+" ")])]),_c('td',[_c('p',[_vm._v(" "+_vm._s(item.makeUpShift)+" ")])]),_c('td',[_c('p',[_vm._v(" "+_vm._s(item.remarkAttendance)+" ")])])])])})}}])}),_c('div',{staticClass:"pl-10 mt-3 pr-10",attrs:{"color":"#fff"}},[_c('v-row',[_c('v-col',{attrs:{"md":"2"}},[_c('v-select',{staticStyle:{"height":"32px"},attrs:{"outlined":"","hide-details":"","dense":"","value":_vm.itemsPerPage,"items":[20, 50, 100, 200]},on:{"change":function($event){_vm.itemsPerPage = parseInt($event, 10)}}})],1),_c('v-col',{staticClass:"d-flex align-center justify-end",attrs:{"md":"4"}},[_c('h3')]),_c('v-col',{staticClass:"d-flex justify-end",attrs:{"md":"6"}},[_c('v-pagination',{attrs:{"length":_vm.pageCount,"elevation":"0","total-visible":7},model:{value:(_vm.page),callback:function ($$v) {_vm.page=$$v},expression:"page"}})],1)],1)],1),(_vm.dialogForm)?_c('FormMakeupLesson',{attrs:{"makeupInfor":Object.assign({}, _vm.makeupInfor,
        {classDate: _vm.makeupInfor.classDate.split('/').reverse().join('-')}),"dialog":_vm.dialogForm},on:{"setLog":_vm.setLog,"callData":_vm.init}}):_vm._e(),_c('v-row',{attrs:{"justify":"center"}},[_c('v-dialog',{attrs:{"persistent":"","width":"500"},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',{staticClass:"mx-auto pa-5"},[_c('div',{staticClass:"text-center"},[_c('h2',[_vm._v("Attendance Note")])]),_c('v-btn',{staticStyle:{"display":"absolute","margin-top":"-100px","margin-left":"400px"},attrs:{"icon":""},on:{"click":function($event){_vm.dialog = !_vm.dialog}}},[_c('v-icon',[_vm._v("mdi-close")])],1),_c('div',{staticClass:"text-center"},[_c('v-container',{attrs:{"fluid":""}},[_c('v-textarea',{staticStyle:{"margin-top":"-30px"},attrs:{"name":"input-7-1","filled":"","auto-grow":""},model:{value:(_vm.selectedItem.remarkAttendance),callback:function ($$v) {_vm.$set(_vm.selectedItem, "remarkAttendance", $$v)},expression:"selectedItem.remarkAttendance"}})],1)],1),_c('div',[_c('v-row',[_c('v-col',{attrs:{"cols":"6"}}),_c('v-col',{attrs:{"cols":"6"}},[_c('div',{staticClass:"d-flex"},[_c('v-btn',{staticClass:"mr-3",attrs:{"width":"100"},on:{"click":function($event){_vm.dialog = !_vm.dialog}}},[_vm._v("Cancel")]),_c('v-btn',{attrs:{"width":"100","color":"primary"},on:{"click":_vm.saveRemark}},[_vm._v("Save")])],1)])],1)],1)],1)],1)],1)]],2)}
var staticRenderFns = []

export { render, staticRenderFns }