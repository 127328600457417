<template>
  <div class="absent">
    <h2>Absent students report</h2>
    <v-row :class="'absent-header'">
      <v-col cols>
        <div :class="' d-flex justify-start'">
          <v-select
            v-model="locationID"
            item-text="location"
            append-icon="keyboard_arrow_down"
            item-value="locationID"
            :items="listLocation"
            label="Center"
            outlined
            :class="'select-input'"
            dense
          ></v-select>

          <v-text-field
            style="margin-left: -20px"
            type="date"
            v-model="fromDate"
            label="Select date"
            :class="'select-input'"
            outlined
            dense
            hide-details
            @change="changeFromDate"
          />
          <v-text-field
            style="margin-left: -20px"
            type="date"
            v-model="toDate"
            label="Select date"
            :class="'select-input'"
            outlined
            dense
            hide-details
            @change="changeToDate"
          /></div
      ></v-col>
      <v-col>
        <div class="btn-download">
          <v-btn elevation="0" height="36" color="primary" @click="download()">
            Download file excel
          </v-btn>
        </div></v-col
      >
    </v-row>
    <h4>Total : {{ listStudent.length }} students</h4>
    <template>
      <v-data-table
        :headers="headers"
        :items="filtered"
        :page.sync="page"
        :items-per-page="itemsPerPage"
        @page-count="pageCount = $event"
        hide-default-footer
        class="elevation-0 absent-table"
        :id="'absent-table'"
        fixed-header
        height="60vh"
      >
        <template v-slot:header="{ props }">
          <tr class="fixed-row-filter">
            <th v-for="header in props.headers" :key="header.text">
              <div v-if="filters.hasOwnProperty(header.value)">
                <Autocomplete
                  v-if="filters.hasOwnProperty(header.value)"
                  @setFilter="setFilter"
                  :styleBorder="'border-bottom'"
                  :keyFilter="header.value"
                  :selectedInit="filters[header.value]"
                  :listItem="groupColumnValueList(header.value)"
                />
              </div>
            </th>
          </tr>
        </template>

        <template v-slot:[`item.classcode`]="{ item }">
          <p style="width: 150px" class="link" @click="viewClassDetail(item.classID)">
            {{ item.classcode }}
          </p>
        </template>
        <template v-slot:[`item.makeUpClassCode`]="{ item }">
          <p style="width: 150px" class="link" @click="viewClassDetail(item.makeUpClassID)">
            {{ item.makeUpClassCode }}
          </p>
        </template>
        <template v-slot:[`item.no`]="{ index }">
          <p>{{ index + (page - 1) * 20 + 1 }}</p>
        </template>
        <template v-slot:[`item.classGroup`]="{ item }">
          <p :class="'type-makup lesson-type'" v-if="item.classGroup === 'Make-up'">
            {{ item.classGroup }}
          </p>
          <p :class="'type-public lesson-type'" v-if="item.classGroup === 'Official'">Official</p>
        </template>
        <template v-slot:[`item.fullname`]="{ item }">
          <p style="width: 200px" class="link" @click="viewStudent(item.student_userID)">
            {{ item.fullname }}
          </p>
        </template>
        <template v-slot:[`item.qc`]="{ item }">
          <p style="width: 200px" class="link" @click="viewStaff(item.qC_userID)">{{ item.qc }}</p>
        </template>
        <template v-slot:[`item.ec`]="{ item }">
          <p style="width: 200px" class="link" @click="viewStudent(item.eC_userID)">
            {{ item.ec }}
          </p>
        </template>
        <template v-slot:[`item.remarkAttendance`]="{ item }">
          <div style="width: 150px" class="d-flex" @click="openEdit(item)">
            <img src="../../assets/images/absent_report_edit_note.svg" alt="" class="mr-1" />
            <p>{{ item.remarkAttendance }}</p>
          </div>
        </template>
        <template v-slot:[`item.mkStatus`]="{ item }">
          <div :style="{ width: 'fit-content', margin: '0 auto' }">
            <img
              :style="{
                width: item.mkStatus === 'Absent' ? '34px' : '27px',
              }"
              :src="
                item.mkStatus === 'Absent'
                  ? require('../../assets/images/icon-close-red.svg')
                  : item.mkStatus === 'Attended'
                  ? require('../../assets/images/icon-done.svg')
                  : item.mkStatus === 'Pending'
                  ? require('../../assets/images/icon-pendding.svg')
                  : ''
              "
              alt=""
              srcset=""
            />
          </div>
        </template>
        <template v-slot:[`item.action`]="{ item }">
          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <img
                :style="{
                  opacity:
                    !(
                      item.classGroup === 'Make-up' ||
                      dayjs().isBefore(dayjs(item.makeUpDateWithShift))
                    ) && item.makeUpID
                      ? 0.4
                      : 1,
                }"
                v-bind="attrs"
                v-on="on"
                @click="
                  item.classGroup === 'Make-up' ||
                    (item.makeUpDate && dayjs().isAfter(dayjs(item.makeUpDateWithShift))) ||
                    openForm(item)
                "
                src="../../assets/images/icon-edit-pen.svg"
              />
            </template>
            <span>
              {{
                item.classGroup === 'Make-up' ||
                (item.makeUpDate && dayjs().isAfter(dayjs(item.makeUpDateWithShift)))
                  ? "Can't Add/Edit"
                  : 'Add/Edit'
              }}</span
            >
          </v-tooltip>
        </template>
      </v-data-table>
      <v-data-table
        :headers="headers"
        :items="listStudent"
        :items-per-page="10000000000"
        hide-default-footer
        id="stu-absent-table"
        fixed-header
        height="0vh"
      >
        <template v-slot:body="{ items }">
          <tbody v-for="(item, index) in items" :key="index">
            <tr>
              <td>
                <p>{{ index + 1 }}</p>
              </td>
              <td>
                <p>
                  {{ item.studentCode }}
                </p>
              </td>
              <td>
                <p>
                  {{ item.fullname }}
                </p>
              </td>
              <td>
                <p>
                  {{ item.mobilePhone }}
                </p>
              </td>
              <td>
                <p>
                  {{ item.classcode }}
                </p>
              </td>
              <td>
                <p>
                  {{ item.classDate }}
                </p>
              </td>
              <td>
                <p>
                  {{ item.classTime }}
                </p>
              </td>
              <td>
                <p>
                  {{ item.absentDate }}
                </p>
              </td>
              <td>
                <p>
                  {{ item.classGroup }}
                </p>
              </td>
              <td>
                <p>
                  {{ item.qc }}
                </p>
              </td>
              <td>
                <p>
                  {{ item.ec }}
                </p>
              </td>
              <td>
                <p>
                  {{ item.mkStatus }}
                </p>
              </td>
              <td>
                <p>
                  {{ item.makeUpClassCode }}
                </p>
              </td>
              <td>
                <p>
                  {{ item.makeUpDate }}
                </p>
              </td>
              <td>
                <p>
                  {{ item.makeUpShift }}
                </p>
              </td>
              <td>
                <p>
                  {{ item.remarkAttendance }}
                </p>
              </td>
            </tr>
          </tbody>
        </template>
      </v-data-table>
      <div color="#fff" class="pl-10 mt-3 pr-10">
        <v-row>
          <v-col md="2">
            <v-select
              outlined
              hide-details
              dense
              :value="itemsPerPage"
              :items="[20, 50, 100, 200]"
              @change="itemsPerPage = parseInt($event, 10)"
              style="height: 32px"
            ></v-select>
          </v-col>
          <v-col md="4" class="d-flex align-center justify-end">
            <h3></h3>
          </v-col>
          <v-col md="6" class="d-flex justify-end">
            <v-pagination
              v-model="page"
              :length="pageCount"
              elevation="0"
              :total-visible="7"
            ></v-pagination>
          </v-col>
        </v-row>
      </div>
      <FormMakeupLesson
        v-if="dialogForm"
        @setLog="setLog"
        @callData="init"
        :makeupInfor="{
          ...makeupInfor,
          classDate: makeupInfor.classDate.split('/').reverse().join('-'),
        }"
        :dialog="dialogForm"
      />
      <v-row justify="center">
        <v-dialog v-model="dialog" persistent width="500">
          <v-card class="mx-auto pa-5">
            <div class="text-center">
              <h2>Attendance Note</h2>
            </div>
            <v-btn
              style="display: absolute; margin-top: -100px; margin-left: 400px"
              icon
              @click="dialog = !dialog"
              ><v-icon>mdi-close</v-icon></v-btn
            >
            <div class="text-center">
              <v-container fluid>
                <v-textarea
                  style="margin-top: -30px"
                  name="input-7-1"
                  filled
                  auto-grow
                  v-model="selectedItem.remarkAttendance"
                ></v-textarea>
              </v-container>
            </div>
            <div>
              <v-row>
                <v-col cols="6"></v-col>
                <v-col cols="6">
                  <div class="d-flex">
                    <v-btn width="100" class="mr-3" @click="dialog = !dialog">Cancel</v-btn>
                    <v-btn width="100" @click="saveRemark" color="primary">Save</v-btn>
                  </div>
                </v-col>
              </v-row>
            </div>
          </v-card>
        </v-dialog>
      </v-row>
    </template>
  </div>
</template>

<script>
import { statistic_management } from '@/api/statistic-management'
import { class_managerment } from '@/api/class-management'
import FormMakeupLesson from './FormMakeupLesson.vue'
import Autocomplete from '@/components/Utils/Autocomplete.vue'
import XLSX from 'xlsx-js-style'
import dayjs from 'dayjs'
export default {
  components: { Autocomplete, FormMakeupLesson },
  data() {
    return {
      listStudent: [],
      page: 1,
      pageCount: 0,
      itemsPerPage: 100,
      dialog: false,
      selectedItem: {},
      makeupInfor: {},
      dialogForm: false,
      fromDate: new Date().toISOString().split('T')[0],
      toDate: new Date().toISOString().split('T')[0],
      listLocation: [],
      locationID: 1,
      headers: [
        {
          text: 'No',
          value: 'no',
          sortable: false,
        },
        {
          text: 'Student code',
          value: 'studentCode',
          sortable: false,
        },
        {
          text: 'Student name',
          value: 'fullname',
          sortable: false,
        },
        {
          text: 'Phone',
          value: 'mobilePhone',
          sortable: false,
        },
        {
          text: 'Class code',
          value: 'classcode',
          sortable: false,
        },
        {
          text: 'Class date',
          value: 'classDate',
          sortable: false,
        },
        {
          text: 'Time',
          value: 'classTime',
          sortable: false,
        },
        {
          text: 'Absent Date',
          value: 'absentDate',
          sortable: false,
        },
        {
          text: 'Lesson type',
          value: 'classGroup',
          sortable: false,
        },
        {
          text: 'QC',
          value: 'qc',
          sortable: false,
        },
        {
          text: 'EC',
          value: 'ec',
          sortable: false,
        },
        {
          text: 'Status',
          value: 'mkStatus',
          sortable: false,
        },
        {
          text: 'Make-up class',
          value: 'makeUpClassCode',
          sortable: false,
        },
        {
          text: 'Make-up date',
          value: 'makeUpDate',
          sortable: false,
        },
        {
          text: 'Make-up shift',
          value: 'makeUpShift',
          sortable: false,
        },
        {
          text: 'Attendance Note',
          value: 'remarkAttendance',
          sortable: false,
        },
        {
          text: 'Action',
          value: 'action',
          sortable: false,
        },
      ],
      filters: {
        classcode: [],
        studentCode: [],
        classDate: [],
        qc: [],
        ec: [],
        mkStatus: [],
        makeUpClassCode: [],
        fullname: [],
        classTime: [],
        mobilePhone: [],
        makeUpShift: [],
        makeUpDate: [],
        classGroup: [],
        absentDate: [],
        remarkAttendance: [],
      },
    }
  },
  computed: {
    filtered() {
      return this.listStudent.filter(d => {
        return Object.keys(this.filters).every(f => {
          return this.filters[f].length < 1 || this.filters[f].includes(d[f])
        })
      })
    },
  },
  setup() {
    return {
      dayjs: dayjs,
    }
  },
  created() {
    this.listLocation = JSON.parse(localStorage.getItem('currentUser')).locationPermissions
    this.locationID = parseInt(localStorage.getItem('currentCenter'))
    if (localStorage.getItem('absCenter'))
      this.locationID = parseInt(localStorage.getItem('absCenter'))
    if (localStorage.getItem('absFromDate')) this.fromDate = localStorage.getItem('absFromDate')
    if (localStorage.getItem('absToDate')) this.toDate = localStorage.getItem('absToDate')
    if (localStorage.getItem('absentStudent'))
      this.filters = JSON.parse(localStorage.getItem('absentStudent'))
    this.init()
  },

  watch: {
    fromDate: function () {
      localStorage.setItem('absFromDate', this.fromDate)
      this.init()
    },
    toDate: function () {
      localStorage.setItem('absToDate', this.toDate)
      this.init()
    },
    locationID: function () {
      localStorage.setItem('absCenter', this.locationID)
      this.init()
    },
  },
  methods: {
    openEdit(item) {
      this.selectedItem = item
      this.dialog = true
    },
    getTimeInobject(shift) {
      if (shift && shift.split('-')[0].indexOf(':') !== -1) {
        let arrShift = shift.split('-')[0].split(':')
        return {
          hour: !isNaN(arrShift[0]) ? parseInt(arrShift[0]) : null,
          min: !isNaN(arrShift[1]) ? parseInt(arrShift[1]) : null,
        }
      }
      return null
    },
    setFilter(objectFilterChange) {
      this.filters = {
        ...this.filters,
        [objectFilterChange.name]: objectFilterChange.filter,
      }
      localStorage.setItem('absentStudent', JSON.stringify(this.filters))
    },
    setLog(data) {
      this.dialogForm = data.openDialog ? data.openDialog : false
      this.makeupInfor = data.makeup ? data.makeupInfor : ''
    },
    openForm(makeupInfor) {
      this.makeupInfor = makeupInfor
      this.dialogForm = true
    },
    groupColumnValueList(val) {
      return this.listStudent.map(d => d[val]).filter(y => y)
    },
    toggle(value) {
      this.$nextTick(() => {
        if (
          this.filters[value].length ==
          this.listStudent
            .map(d => d[value])
            .reduce(function (accumulator, element) {
              if (accumulator.indexOf(element) === -1) {
                accumulator.push(element)
              }
              return accumulator
            }, []).length
        ) {
          this.filters[value] = []
        } else {
          this.filters[value] = this.listStudent
            .map(d => d[value])
            .reduce(function (accumulator, element) {
              if (accumulator.indexOf(element) === -1) {
                accumulator.push(element)
              }
              return accumulator
            }, [])
            .slice()
        }
      })
    },
    viewStudent(studentID) {
      this.$router.push({
        name: 'account-management-view-account',
        params: {
          userId: studentID,
        },
      })
    },
    viewStaff(userID) {
      this.$router.push({
        name: 'account-management-view-account',
        params: {
          userId: userID,
        },
      })
    },
    viewClassDetail(classID) {
      this.$router.push({
        name: 'class-management-detail',
        query: {
          classID: classID,
        },
      })
    },
    changeFromDate(val) {
      this.fromDate = val
    },
    changeToDate(val) {
      this.toDate = val
    },
    async init() {
      await statistic_management
        .getStudentAbsentReport(this.locationID, this.fromDate, this.toDate)
        .then(res => {
          this.listStudent = res.map(item => {
            return {
              ...item,
              makeUpDate: item.makeUpDate ? dayjs(item.makeUpDate).format('DD/MM/YYYY') : '',
              classGroup: item.classGroup === 'Public' ? 'Official' : item.classGroup,
              classDate: item.classDate ? dayjs(item.classDate).format('DD/MM/YYYY') : '',
              absentDate: item.absentDate ? dayjs(item.absentDate).format('DD/MM/YYYY') : '',
              mkStatus: !item.mkStatus && item.makeUpID ? 'Pending' : item.mkStatus,
              makeUpDateWithShift:
                this.getTimeInobject(item.makeUpShift) &&
                dayjs(item.makeUpDate)
                  .add(this.getTimeInobject(item.makeUpShift).hour, 'hour')
                  .add(this.getTimeInobject(item.makeUpShift).min, 'minute'),
              // no: index + 1,
              // classGroup: index === 2 ? 'Make-up' : 'Public',
              // makeUpID: index === 3 || index === 2 ? null : 2323,
              // makeUpClassID: index === 3 || index === 2 ? null : 2910,
              // makeUpClassCode: index === 3 || index === 2 ? null : 'VQ1-B1-2203',
              // makeUpLessonNo: index === 3 || index === 2 ? null : 1,
              // makeUpDate: index === 3 || index === 2 ? null : ' 2023-03-11',
              // makeUpShift: index === 3 || index === 2 ? null : '17:30 - 19:00',
            }
          })
        })
    },
    async saveRemark() {
      const students = []
      students.push({
        studentID: this.studentID,
        registrationID: this.selectedItem.registrationID,
        isJoinToLesson: false,
        remarkAttendance: this.selectedItem.remarkAttendance,
        ordinalNo: parseInt(this.selectedItem.ordinalNo),
        homework: this.selectedItem.homework,
        remarkHomeWork: this.selectedItem.remarkHomeWork,
      })

      const body = {
        students: students,
      }
      await class_managerment.updateAttendanceStudent(
        this.selectedItem.classID,
        this.selectedItem.scheduleID,
        body
      )

      this.dialog = false
      this.init()
    },
    async download() {
      var table_elt = document.getElementById('stu-absent-table')
      var workbook = XLSX.utils.table_to_book(table_elt)
      XLSX.writeFile(
        workbook,
        'Absent_students_report from ' +
          dayjs(this.fromDate).format('DD-MM-YYYY') +
          ' to ' +
          dayjs(this.toDate).format('DD-MM-YYYY') +
          '.xlsm'
      )
      this.isDownload = false
    },
  },
}
</script>

<style lang="scss" scoped>
.lesson-type {
  width: 80px;
  height: 30px;
  color: #ffff;
  line-height: 30px;
  border-radius: 4px;
  text-align: center;

  &.type-public {
    background: #27ae60;
  }
  &.type-makup {
    background: #f6b91a;
  }
}
.absent-btn-download {
  width: fit-content;
  margin-left: auto;
}
.select-input {
  max-width: 300px;
  margin-right: 40px !important;
}
h4 {
  margin-bottom: 14px;
}
h2 {
  margin-bottom: 20px;
}
.absent {
  padding: 20px 36px;
}
.absent-table {
  border-radius: 5px;
  overflow: hidden;
}
.btn-download {
  width: fit-content;
  margin-left: auto;
}
.link {
}
.link {
  cursor: pointer;

  color: #427df2 !important;
}

.link:hover {
  text-decoration: underline;
  font-weight: 700;
}
</style>
